.signature-well {
  border: 1px solid rgba(153, 153, 153, 0.3);
  border-radius: 3px;
  padding: 10px 10px 0px 10px;
  margin: 10px 0px 10px 0px;
  position: relative;
}

.signature-button-padding {
  margin-bottom: 30px;
}

.sig-canvas {
  border: 1px solid rgba(153, 153, 153, 0.3);
  border-radius: 3px;
  width: 100%;
  height: 100%;
}

.signature-holder {
  height: 150px;
}

.overlay {
  background: rgba(153, 153, 153, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2019;
}

.loader {
  text-align: center;
  padding: 40px;
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: 800;
}

.loader svg {
  font-size: 4rem;
}
